body {
  margin: 0;
  overflow: hidden;
  height: 100vh;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

progress::-webkit-progress-value { 
  background-color: rgba(0,0,0,0.5); 
} 
 
progress::-moz-progress-bar { 
  background-color: rgba(0,0,0,0.5); 
}

[contentEditable=true]:empty:not(:focus):before {
  content: attr(data-text)
}
